import styles from "./styles.module.css";
import Coin from "@/assets/capybara-images/coin.svg?react";
import Info from "@/assets/layout/rating/info.svg?react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import state from "./tapState";
import CoinTap from "./CoinTap";
import { createPortal } from "react-dom";
import NftBorder from "@/assets/capybara-images/nftBorder.png";
import NftImg from "@/assets/capybara-images/nftImg.png";
import MintinImg from "@/assets/capybara-images/MintingBackground.png";
import { Badge } from "../Badge";
import clsx from "clsx";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import SuiImg from "@/assets/capybara-images/sui-80.png";
import { useNavigate } from "react-router-dom";

const getNftImg = (
  capybaraNftStatus: "MINTED" | "MINTING" | "MINTABLE" | "UPDATABLE"
) => {
  switch (capybaraNftStatus) {
    case "MINTED":
    case "MINTABLE":
      return NftImg;
    case "MINTING":
      return MintinImg;
    case "UPDATABLE":
      return MintinImg;
  }
};

export const CapybaraNftCard = observer(() => {
  const [capybaraNftStatus, setCapybaraNftStatus] = useState<
    "MINTED" | "MINTING" | "MINTABLE" | "UPDATABLE"
  >("MINTABLE");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    Telegram.WebApp.setHeaderColor("#fff");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    return () => {
      Telegram.WebApp.BackButton.hide();
    };
  }, []);

  return (
    <div className={styles.capybaraContainer}>
      <div
        className={clsx(styles.capybaraCard, {
          [styles.mintable]: capybaraNftStatus === "MINTABLE",
          [styles.minting]: capybaraNftStatus === "MINTING",
          [styles.minted]: capybaraNftStatus === "MINTED",
        })}
      >
        <div className={styles.additionalDataText}>
          {capybaraNftStatus === "MINTABLE" && <Info />}
          {capybaraNftStatus === "MINTING" ||
            (capybaraNftStatus === "MINTED" && (
              <p>
                Player Card
                <br /> NFT
              </p>
            ))}
        </div>
        <img className={styles.nftBackground} src={NftBorder} />
        <img
          draggable="false"
          className={styles.capybaraImg}
          src={getNftImg(capybaraNftStatus)}
          alt="state"
        />
        {capybaraNftStatus === "MINTABLE" && (
          <div
            onClick={() => setCapybaraNftStatus("MINTING")}
            className={styles.capybaraCardBageContainer}
          >
            <Badge>
              <p className={styles.nftBageText}>🔒 MINT PLAYER CARD NFT</p>
            </Badge>
          </div>
        )}
        {(capybaraNftStatus === "MINTING" ||
          capybaraNftStatus === "MINTED") && (
          <div
            className={clsx(styles.capybaraAttributesWrapper, {
              [styles.capybaraAttributesMinted]: capybaraNftStatus === "MINTED",
            })}
          >
            <div className={styles.capybaraAttributesContainer}>
              <p className={clsx(styles.capybaraAttributesTitle)}>
                Attributes:
              </p>
              <div className={styles.capybaraAttributesBlock}>
                <p>
                  Coin Earned:
                  <Coin width={12} height={12} /> 1000
                </p>
                <p> League: Starving </p>
              </div>
            </div>
            {capybaraNftStatus === "MINTED" && (
              <div
                onClick={() => setIsOpen(true)}
                className={styles.capybaraAttributesTimer}
              >
                <p className={styles.title}>Update in:</p>
                <p className={styles.timer}>22h 30m</p>
              </div>
            )}
          </div>
        )}
      </div>
      {capybaraNftStatus === "MINTING" && (
        <div className={styles.feeContainer}>
          <div className={styles.feeInfo}>
            <p>Transaction Fee</p>
            <p className={styles.amount}>0.001 SUI</p>
          </div>
          <Info />
        </div>
      )}
      {capybaraNftStatus === "MINTING" && (
        <Button
          onClick={() => setCapybaraNftStatus("MINTED")}
          block={true}
          label={"Mint Player Card NFT"}
        />
      )}
      {createPortal(
        <div
          style={{
            position: "absolute",
            inset: 0,
            pointerEvents: "none",
            overflow: "hidden",
          }}
        >
          {state.taps.map((tap, i) => (
            <CoinTap key={i} tap={tap}></CoinTap>
          ))}
        </div>,
        document.body
      )}
      <Dialog
        modalWallpaper={""}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onAction={() => navigate("/wallet")}
        title={"Not enough SUI"}
        text={`You don’t have enough SUI in your wallet to cover the Transaction Fee.
           Please Buy SUI or deposit from external wallet.`}
        modalTitleColor={"#C0E6FF;"}
        modalIcon={<img width={80} height={80} src={SuiImg} />}
        buttonText={"Buy"}
        additionalbuttonText="Deposit"
        handleAdditionalAction={() => navigate("/wallet?receive=true")}
      />
    </div>
  );
});
