import { action, computed, observable, reaction } from "mobx";
import type RootStore from "./RootStore";
import { LeagueOption } from "@/services/types";
import { gameStore } from ".";
import { backend } from "@/services";

export default class LeagueStore {
  rootStore: RootStore;
  @observable public accessor levelUpDialog: boolean = false;
  @observable private accessor levels: LeagueOption[] = [];
  @observable public accessor _level: number = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action init(leagueOptions: LeagueOption[]) {
    this.levels = [...leagueOptions].sort((a, b) => a.level - b.level);
  }

  @action initState(level: number) {
    this._level = level || 0;
    reaction(() => this.level, this.checkLevel);
    this.checkLevel();
  }

  public levelForBalance(balance: number) {
    let result = 0;
    this.levels.forEach((level) => {
      if (balance >= level.threshold) {
        result = level.level;
      }
    });
    return result;
  }

  @computed public get level() {
    const balanceLevel = this.levelForBalance(gameStore.balance);
    return Math.max(balanceLevel, this._level);
  }

  @computed public get maxLevel() {
    return Math.max(...this.levels.map((l) => l.level));
  }

  @computed public get isMaxed() {
    return this.level == this.maxLevel;
  }

  @computed public get current() {
    return this.levels.find((l) => l.level === this.level)!;
  }

  @computed public get next() {
    return this.levels.find((l) => l.level === this.level + 1);
  }

  @computed public get leagues() {
    return this.levels.slice().sort((a, b) => a.level - b.level);
  }

  @computed public get currentBonus() {
    return this.current.lvlupBonus;
  }

  @computed public get leagueName() {
    return this.current.leagueNameEn;
  }

  @action.bound private checkLevel() {
    if (this.level > this._level) {
      gameStore.addBalance(this.currentBonus);
      backend.sendTaps();
      this.levelUpDialog = true;
      this._level = this.level;
    }
  }

  @action.bound public closeDialog() {
    this.levelUpDialog = false;
  }
}
