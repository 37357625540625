import { Button } from "@/components/Button";
import WalletInput from "../WalletInput";
import styles from "./styles.module.sass";
import { observer } from "mobx-react-lite";
import { walletStore } from "@/store";
import { balance } from "@/utils";
import { useEffect, useState } from "react";
import { useCurrentAccount, useSuiClient } from "@mysten/dapp-kit";
import { useNavigate } from "react-router-dom";
import { isIOS, openExternalUrl } from "@/utils/handleExpandPage";

let timeout: NodeJS.Timeout | null = null;

const SendToAdderess = observer(() => {
  const [amount, setAmount] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false);
  const isAmoutValid =
    Number(amount) > 0 && Number(amount) <= +balance(walletStore.balance);
  const client = useSuiClient();
  const currentAccount = useCurrentAccount();
  const navigate = useNavigate();

  const focus = () => {
    navigate("/wallet", { replace: true });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout!);
      window.removeEventListener("focus", focus);
    };
  }, []);

  return (
    <div className={styles.sendToAdderessWrapper}>
      <div className={styles.walletFinanceBlock}>
        <h2 className={styles.title}>Send to wallet address</h2>
        <p className={styles.hint}>
          The funds will immediately be sent directly to the specified wallet
          address below. This transaction is final.
        </p>
        <div className={styles.form}>
          <WalletInput
            labelSize="xl"
            label="SUI amount"
            onChange={(val) => setAmount(val)}
            name="amount"
            hint={`Current balance: ${balance(walletStore.balance)} SUI`}
            error={!isAmoutValid ? "Insufficient funds" : undefined}
          />
          <WalletInput
            wrap={true}
            error={!isAddressValid ? "Invalid address" : undefined}
            label="Recipient wallet address"
            onChange={(val) => {
              clearTimeout(timeout!);
              if (val.length > 3) {
                timeout = setTimeout(() => {
                  client
                    .getBalance({
                      owner: val,
                    })
                    .then(() => {
                      setIsAddressValid(true);
                      setAddress(val);
                    })
                    .catch(() => {
                      setIsAddressValid(false);
                    });
                }, 300);
              }
            }}
            name="address"
          />
        </div>
      </div>
      <Button
        disabled={!(isAddressValid && isAmoutValid)}
        onClick={() => {
          if (isIOS) {
            window.addEventListener("focus", focus);
          }
          openExternalUrl(
            `${window.location.protocol}//${window.location.host}/transferStashed?reciever=${address}&amount=${amount}&sender=${currentAccount?.address}`
          );
          navigate("/wallet", { replace: true });
        }}
        block={true}
        label="Send to Wallet"
      />
    </div>
  );
});

export default SendToAdderess;
