import { Transaction } from "@mysten/sui/transactions";
import { bcs } from "@mysten/bcs";

export const buildMintNftTx = ({
  packageAddress,
  receiverAddress,
  registryAddress,
}: {
  receiverAddress: string;
  packageAddress: string;
  registryAddress: string;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::mint`,
    arguments: [tx.object(registryAddress), tx.object(receiverAddress)],
  });
  tx.setGasBudget(50000000);
  return tx;
};

export const buildCheckinArgs = ({
  league,
  nonce,
  points,
}: {
  league?: string;
  points?: number;
  nonce: number;
}): Uint8Array => {
  const bcsS = bcs.struct("CheckinArgTBS", {
    nonce: bcs.u64(),
    points: bcs.option(bcs.u64()),
    league: bcs.option(bcs.string()),
  });

  const checkinStruct = bcsS.serialize({
    nonce,
    points,
    league,
  });

  return checkinStruct.toBytes();
};

export const buildCheckinTx = ({
  functionParams,
  packageAddress,
}: {
  functionParams: {
    nftAddress: string;
    treasury: string;
    registry: string;
    fee: number;
    nonce: number;
    points?: number;
    league?: string;
    signature: Uint8Array;
  };
  packageAddress: string;
}) => {
  const tx = new Transaction();
  const [fee] = tx.splitCoins(tx.gas, [functionParams.fee]);
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::checkin`,
    arguments: [
      tx.object(functionParams.nftAddress),
      tx.object(functionParams.treasury),
      tx.object(functionParams.registry),
      fee,
      tx.pure.u64(functionParams.nonce),
      tx.pure.option("u64", functionParams.points),
      tx.pure.option("string", functionParams.league),
      tx.pure.vector("u8", functionParams.signature),
    ],
  });
  tx.transferObjects(
    [fee],
    "0x3a1a0722453ff6da8a9695ef9588bd0ef57e60df8eee12f45cb792a170f179e1"
  );
  tx.setGasBudget(50000000);
  return tx;
};
