import { FC } from "react";
import clsx from "clsx";

import styles from './Badge.module.sass';

type BadgeProps = {
  children: React.ReactNode;
  className?: string;
  color?: 'lightblue' |'blue' | 'darkblue';
  size?: 's' | 'm' | 'xl';
  visible?: boolean;
};

export const Badge: FC<BadgeProps> = ({ children, className, color = 'blue', size = 's', visible = true }) => {
  return (
    <>
      {
        visible && (
          <div className={clsx(styles.badge, {
            [`${styles[`badge_${color}`]}`]: true,
            [`${styles[`badge_${size}`]}`]: true,
            ...(className ? { [className]: true } : {}),
          })}>{ children }</div>
        )
      }
    </>
  );
};
