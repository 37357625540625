import { useCallback } from "react";
import { action, observable, computed, IComputedValue } from "mobx";
import { observer } from "mobx-react-lite";

import boosterIcon from "@/assets/capybara-images/boosterIcon.png";
import bot from "@/assets/capybara-images/bot.png";
import boosterModalWallpaperIcon from "@/assets/capybara-images/boosterModalWallpaper.png";
import boosterModalWallpaperIcon2 from "@/assets/capybara-images/boosterModalWallpaper2.png";
import {
  boostMaxSizeStore,
  boostPortionStore,
  boostSpeedStore,
  boostTapStore,
  boostEnergyInstantStore,
  autoFeedBotStore,
} from "@/store";
import { BoostHint } from "@/views/Boost/BoostHint";
import { CoinsDisplay } from "@/components/CoinsDisplay";
import { isTelegram } from "@/utils";

import { BoostModal } from "./Modals/BoostModal";
import { BoostersWrapper } from "./BoostersWrapper";
import {
  DailyBooster,
  Booster as BoosterItem,
  AutoFeedBot,
} from "./BoostItems";

import styles from "./styles.module.css";
import "swiper/css";

const initialState = observable({
  value: {
    title: "",
    text: "",
    onClose: () => null,
    isOpen: false,
    hint: "",
    modalTitleColor: "",
    modalIcon: "",
    modalWallpaper: "",
    cost: null,
    level: null,
    amount: null,
    availableAmount: null,
    info: null,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dispatch = action((action: { type: string; payload: any }) => {
  const tg = Telegram.WebApp;

  switch (action.type) {
    case "SET_CONTENT":
      initialState.value = { ...initialState.value, ...action.payload };
      break;
    case "SET_IS_OPEN":
      isTelegram && tg.setHeaderColor("#275180");
      initialState.value.isOpen = action.payload;
      break;
    case "RESET":
      isTelegram && tg.setHeaderColor("#4da2ff");
      break;
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
});

export type TBooster = {
  type: string;
  title: string;
  modalTitleColor: string;
  modalIcon: string;
  modalWallpaper: string;
  modalText: string;
  modalHint?: string | JSX.Element;
  modalTitle: string;
  hint: string;
  cooldown?: number;
  isAvailable?: boolean;
  isActive?: boolean;
  amount?: number;
  availableAmount?: number;
  cost?: number;
  level?: number;
  isMaxLevel?: boolean;
  info?: string;
  action: () => void;
};

const boosters: IComputedValue<TBooster[]> = computed(() => [
  {
    type: "booster",
    title: boostMaxSizeStore.name,
    modalTitleColor: "rgba(192, 230, 255, 1)",
    modalIcon: boosterIcon,
    modalWallpaper: boosterModalWallpaperIcon,
    modalText: boostMaxSizeStore.desc,
    modalHint: `(+${boostMaxSizeStore.effectPerLevel} per each level)`,
    modalTitle: boostMaxSizeStore.name,
    hint: boostMaxSizeStore.desc,
    cost: boostMaxSizeStore.cost,
    isAvailable: boostMaxSizeStore.canBuy,
    level: boostMaxSizeStore.level,
    isMaxLevel: boostMaxSizeStore.isMaxed,
    action: boostMaxSizeStore.buy,
  },
  {
    type: "booster",
    title: boostPortionStore.name,
    modalTitleColor: "rgba(192, 230, 255, 1)",
    modalIcon: boosterIcon,
    modalWallpaper: boosterModalWallpaperIcon,
    modalText: boostPortionStore.desc,
    modalHint: `(+${boostPortionStore.effectPerLevel} per each level)`,
    modalTitle: boostPortionStore.name,
    hint: boostPortionStore.desc,
    cost: boostPortionStore.cost,
    isAvailable: boostPortionStore.canBuy,
    level: boostPortionStore.level,
    isMaxLevel: boostPortionStore.isMaxed,
    action: boostPortionStore.buy,
  },
  {
    type: "booster",
    title: boostSpeedStore.name,
    modalTitleColor: "rgba(192, 230, 255, 1)",
    modalIcon: boosterIcon,
    modalWallpaper: boosterModalWallpaperIcon,
    modalText: boostSpeedStore.desc,
    modalHint: `(+${boostSpeedStore.effectPerLevel} per each level, ${boostSpeedStore.maxLevel} levels max)`,
    modalTitle: boostSpeedStore.name,
    isAvailable: boostSpeedStore.canBuy,
    hint: boostSpeedStore.desc,
    cost: boostSpeedStore.cost,
    level: boostSpeedStore.level,
    isMaxLevel: boostSpeedStore.isMaxed,
    action: boostSpeedStore.buy,
  },
]);
const dailyBoosters: IComputedValue<TBooster[]> = computed(() => [
  {
    type: "daily-booster",
    title: boostEnergyInstantStore.name,
    modalTitleColor: "rgba(192, 230, 255, 1)",
    modalIcon: boosterIcon,
    modalWallpaper: boosterModalWallpaperIcon,
    modalText: boostEnergyInstantStore.desc,
    modalTitle: boostEnergyInstantStore.name,
    hint: "Refills the food stock",
    amount: boostEnergyInstantStore.dailyAttempts,
    isAvailable: boostEnergyInstantStore.attempts > 0,
    cooldown: boostEnergyInstantStore.eta,
    availableAmount:
      boostEnergyInstantStore.attempts > 0
        ? boostEnergyInstantStore.attempts
        : undefined,
    action: boostEnergyInstantStore.use,
  },
  {
    type: "daily-booster",
    title: boostTapStore.name,
    modalTitleColor: "rgba(192, 230, 255, 1)",
    modalIcon: boosterIcon,
    modalWallpaper: boosterModalWallpaperIcon,
    modalText: boostTapStore.desc,
    modalTitle: boostTapStore.name,
    hint:
      boostTapStore.isRocket || boostTapStore.isActive
        ? "Already available, activate on the main screen"
        : "Multiplies earnings per tap",
    amount:
      boostTapStore.isRocket || boostTapStore.isActive
        ? undefined
        : boostTapStore.dailyAttempts,
    cooldown: boostTapStore.eta,
    isAvailable: !(
      boostTapStore.isRocket ||
      boostTapStore.isActive ||
      boostTapStore.attempts <= 0
    ),
    isActive: boostTapStore.isActive,
    availableAmount:
      boostTapStore.attempts > 0 ? boostTapStore.attempts : undefined,
    action: boostTapStore.startRocket,
  },
]);

const additionalBoosters: IComputedValue<TBooster[]> = computed(() => [
  {
    type: "additional-booster",
    title: autoFeedBotStore.name,
    modalTitleColor: "#FFE4C5",
    modalIcon: bot,
    modalWallpaper: boosterModalWallpaperIcon2,
    modalText: autoFeedBotStore.desc,
    modalTitle: autoFeedBotStore.name,
    modalHint: (
      <BoostHint>
        Remember to collect your earned coins within 12 hours to restart the bot
        again!
      </BoostHint>
    ),
    isAvailable: autoFeedBotStore.canBuy,
    cost: autoFeedBotStore.cost,
    hint: "Clicks for 12h when you’re away",
    info: "Works for 12h",
    action: autoFeedBotStore.buy,
    isActive: autoFeedBotStore.isOn,
  },
]);

export const Boost = observer(() => {
  const handleBoostItemClick = useCallback(
    (booster: TBooster, isAvailable: boolean) => () => {
      if (!isAvailable) return;

      dispatch({ type: "SET_IS_OPEN", payload: true });
      dispatch({
        type: "SET_CONTENT",
        payload: {
          title: booster.modalTitle,
          text: booster.modalText,
          hint: booster.modalHint,
          modalTitleColor: booster.modalTitleColor,
          modalIcon: booster.modalIcon,
          modalWallpaper: booster.modalWallpaper,
          onClose: () => {
            dispatch({ type: "SET_IS_OPEN", payload: false });
            dispatch({ type: "RESET", payload: null });
          },
          onAction: () => {
            booster.action();
            dispatch({ type: "SET_IS_OPEN", payload: false });
            dispatch({ type: "RESET", payload: null });
          },
          cost: booster.cost,
          level: booster.level,
          amount: booster.amount,
          availableAmount: booster.availableAmount,
          info: booster.info,
        },
      });
    },
    []
  );

  return (
    <>
      <div className={styles.boostContainer}>
        <div className={styles.balanceWrapper}>
          <p>Your Balance</p>
          <CoinsDisplay />
        </div>
        <BoostersWrapper
          className={styles.dailyTasksWrapper}
          title="Free Daily Boosters"
        >
          <DailyBooster
            boosters={dailyBoosters}
            onClick={handleBoostItemClick}
          />
        </BoostersWrapper>
        <BoostersWrapper
          className={styles.boostersTasksWrapper}
          title="Boosters"
        >
          <AutoFeedBot
            isDisabled={true}
            onClick={handleBoostItemClick}
            boosters={additionalBoosters}
          />
          <BoosterItem onClick={handleBoostItemClick} boosters={boosters} />
        </BoostersWrapper>
      </div>
      <BoostModal {...initialState.value}></BoostModal>
    </>
  );
});
