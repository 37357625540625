const overflow = 100;

export const handleExpandPage = () => {
  document.body.style.overflowY = "hidden";
  document.body.style.marginTop = `${overflow}px`;
  document.body.style.height = `${window.innerHeight + overflow}px`;
  document.body.style.paddingBottom = `${overflow}px`;
  window.scrollTo(0, overflow);
};

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export const openExternalUrl = (url: string) => {
  if (isIOS) {
    Telegram.WebApp.openLink(url);
  } else {
    window.open(url, "_blank");
  }
};
