import { useEffect, useState } from "react";
import {
  ConnectModal,
  useCurrentAccount,
  useSignAndExecuteTransaction,
} from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import { isIOS } from "@/utils/handleExpandPage";
import { buildMintNftTx } from "@/utils/mintNft";

const PACKAGE_ADDRESS =
  "0xb9e37116ca8f1223ab5d524aa0bae4921dd59e5621f3c78a35b4d5849f9741c5";
const REGISTRY_ADDRESS =
  "0xbf4c1c1a003dcab5b651aea7b7a81b78599bf20edcc28eb78293ebf64144d142";

export const StashedNftMint = () => {
  const query = new URLSearchParams(window.location.search);
  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);

  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(true);
  // const [isFirstAuth, setIsFirstAuth] = useState(true);
  const currentAccount = useCurrentAccount();

  const transfer = () => {
    setIsLoader(true);
    isError && setIsError(false);

    if (query.has("reciever") && !transacrionInWork) {
      setTransacrionInWork(true);

      const mintNftTx = buildMintNftTx({
        packageAddress: PACKAGE_ADDRESS,
        receiverAddress: query.get("reciever") as string,
        registryAddress: REGISTRY_ADDRESS,
      });

      signAndExecuteTransaction(
        {
          transaction: mintNftTx,
          chain: "sui:mainnet",
        },
        {
          onSuccess: () => {
            if (isIOS) {
              const url = new URL(window.location.href);
              url.search = "";
              window.history.replaceState(null, "", url.toString());
              window.open("https://t.me/getcapybot", "_self");
            } else {
              window.close();
            }
            setIsLoader(false);
          },
          onError: () => {
            setIsLoader(false);
            setIsError(true);
          },
        }
      );
    }
  };

  useEffect(() => {
    localStorage.removeItem("stashed:recentAddress");
    localStorage.removeItem("sui-dapp-kit:wallet-connection-info");
  }, []);

  useEffect(() => {
    if (currentAccount?.address) {
      if (isIOS) {
        document.getElementById("transferButton")?.click();
      }
      setStashedWalletIsOpen(false);
    }
  }, [currentAccount]);

  return (
    <>
      {isLoader && <Loader />}
      {query.has("reciever") && (
        <ConnectModal
          open={stashedWalletIsOpen}
          trigger={<></>}
          onOpenChange={() => {
            console.log();
          }}
        />
      )}
      {query.has("reciever") && !isIOS && (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={!stashedWalletIsOpen}
          onClose={() => {
            if (isIOS) {
              window.open("https://t.me/getcapybot", "_self");
            } else {
              window.close();
            }
          }}
          onAction={() => transfer()}
          title={"Connecting is finished"}
          text={"Click the button below to mint NFT"}
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Approve mint"}
        />
      )}

      <Dialog
        modalWallpaper={walletBg}
        isOpen={isError}
        onClose={() => {
          if (isIOS) {
            window.open("https://t.me/getcapybot", "_self");
          } else {
            window.close();
          }
        }}
        onAction={() => transfer()}
        title={"Something went wrong"}
        text={"Click the button below to try again"}
        modalTitleColor={"#C0C3FF"}
        modalIcon={iosWalletImg}
        buttonText={"Mint NFT again"}
      />
      <button
        style={{
          opacity: 0,
        }}
        onClick={transfer}
        id="transferButton"
      >
        mint
      </button>
    </>
  );
};
