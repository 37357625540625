import Backend from "./Backend";
import {
  RequestMessage,
  ResponseMessage,
  ContentMessage,
  ContentResponse,
  authResponce,
  GameOptions,
  ErrorResponse,
  googleContextResponce,
} from "./types";
import ky from "ky";
import { isProduction, isDevelopment, delay, isBackend } from "@/utils";
import schemas from "./schemas.json";
import schemasContent from "./schemasContent.json";
//import fakeOptions from "./fakeOptions.json"
import snakeKeys from "snakecase-keys";
import camelKeys from "camelcase-keys";
import type { Type, Schema } from "avsc";
import rootStore, { walletStore } from "@/store";
import {
  apiProduction,
  apiStage,
  settingsProduction,
  settingsStage,
} from "@/constants";

interface QueueItem {
  message: ContentMessage | null;
  requestMessage: RequestMessage;
  promise: Promise<ContentResponse>;
  buffer: Buffer;
  schema: string | null;
  resolve: (value: ContentResponse | PromiseLike<ContentResponse>) => void;
  reject: (reason?: unknown) => void;
}

const base = isProduction ? apiProduction : apiStage;
const settings = isProduction ? settingsProduction : settingsStage;

function logPerf(
  // eslint-disable-next-line
  value: Function,
  { kind, name }: { kind: string; name: string }
) {
  if (kind === "method") {
    return async function (this: unknown, ...args: unknown[]) {
      if (!isProduction) console.time(name);
      const ret = await value.call(this, ...args);
      if (!isProduction) console.timeEnd(name);
      return ret;
    };
  }
}

const toResultSchema = {
  SyncMessage: "SyncResponse",
  SyncMessageV2: "SyncResponseV2",
  TapMessage: "TapResponse",
  MissionMessage: "MissionResponse",
  FriendMessage: "FriendResponse",
  FriendMessageV2: "FriendsResponseV2",
  BoostMessage: "TapResponse",
  WalletMessage: "WalletResponse",
};

export default class RealBackend extends Backend {
  protected schemas: Record<string, Type> = {};
  protected avsc: typeof import("avsc") | undefined = undefined;
  protected token: string | undefined;
  protected wsURL = base + "/ws";
  protected settingsHash: string = "hash";
  protected ws: WebSocket | undefined;
  protected queue: QueueItem[] = [];
  protected queueInProgress: boolean = false;
  protected blockQueue: boolean = false;
  protected wsResponseResolve: ((x: ResponseMessage) => void) | undefined;
  protected wsResponsePromise: Promise<ResponseMessage> | undefined;
  protected messageCounter: number = 1;
  constructor() {
    super();
  }

  public get queueSize() {
    return this.queue.length;
  }

  @logPerf async initAvro() {
    this.avsc = await import("avsc");
    for (const schema of [...schemas]) {
      this.schemas[schema.name] = this.avsc.Type.forSchema(schema as Schema);
    }
    rootStore.initFlags.avro = true;
  }

  @logPerf async initContentSchemas() {
    if (isDevelopment || isBackend) {
      for (const schema of [...schemasContent]) {
        this.schemas[schema.name] = this.avsc!.Type.forSchema(schema as Schema);
      }
    } else {
      // const serverSchemasContent = await ky
      //   .get(settings + "/avro", {
      //     headers: { Authorization: `Bearer ${this.token}` },
      //   })
      //   .json();
      let url = settings + "/avro";
      if (isProduction) url += ".json";
      const serverSchemasContent = await ky.get(url).json();
      for (const schema of [...(serverSchemasContent as [])]) {
        // @ts-expect-error wtf
        this.schemas[schema.name] = this.avsc!.Type.forSchema(schema as Schema);
      }
    }
    rootStore.initFlags.avroSchemas = true;
  }

  @logPerf async initAuthToken() {
    if (
      !isProduction &&
      localStorage["devToken"] &&
      localStorage["devToken"] !== "null"
    ) {
      this.token = localStorage["devToken"];
    } else {
      const json: authResponce = await ky
        .post(base + "/auth", { json: { auth: Telegram.WebApp.initData } })
        .json();
      if (!json.ok) {
        throw new Error("/auth fail");
      }
      this.wsURL = json.shard_url;
      this.token = json.token;
      this.settingsHash = json.settings;
      localStorage.setItem("token", this.token);
    }
    rootStore.initFlags.auth = true;
  }

  public async sendContext(
    hash: string,
    token: string,
    provider: "google" | "trust_wallet"
  ) {
    await ky
      .post(base + `/api/context?provider=${provider}`, {
        headers: { Authorization: `Bearer ${token}` },
        json: {
          hash: hash,
        },
      })
      .json();
  }

  public async getContext(provider: "google" | "trust_wallet") {
    try {
      const json: googleContextResponce = await ky
        .get(base + `/api/context?provider=${provider}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .json();

      if (provider === "google") {
        const url = new URL(window.location.href);
        const hashParams = new URLSearchParams(url.hash.slice(1));

        hashParams.set("id_token", json.hash);
        const newHash = hashParams.toString();

        window.location.href = `${url.origin}${url.pathname}#${newHash}`;

        walletStore.setEnokiHash(json.hash);
      }

      if (provider === "trust_wallet") {
        this.sendWallet(json.hash);

        localStorage.setItem("stashed:recentAddress", json.hash);
        localStorage.setItem(
          "sui-dapp-kit:wallet-connection-info",
          JSON.stringify({
            state: {
              lastConnectedWalletName: "Stashed",
              lastConnectedAccountAddress: json.hash,
            },
            version: 0,
          })
        );

        walletStore.chageCreateStatus(true);
        walletStore.setStashedAddress(json.hash);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public sendWallet(hash: string) {
    this.send({ action: "wallet", wallet: hash }, "WalletMessage");
  }

  public logoutWallet() {
    this.send({ action: "wallet", wallet: null }, "WalletMessage");
  }

  @logPerf async initOptions() {
    try {
      let url = settings + "/objects";
      url += `/${this.settingsHash}.json`;
      let json = await ky.get(url).json();
      await delay(0);
      if ((json as Record<string, string>).static_url) {
        const staticURL = (json as Record<string, string>).static_url;
        json = await ky.get(staticURL).json();
        await delay(0);
      }
      const camel = camelKeys(json as Record<string, unknown>, {
        deep: true,
        stopPaths: ["game_objects.conditions"],
      });
      this.options = camel as unknown as GameOptions;
      // @ts-expect-error global for dev
      if (isDevelopment) window.gameOptions = this.options;
    } catch (e) {
      if (isProduction || isBackend) {
        throw e;
      } else {
        console.warn("Fallback to local options");
        this.options = await import("./localOptions.json");
      }
    }
    rootStore.initFlags.objects = true;
  }

  @logPerf async initWs() {
    const wsOpen = Promise.withResolvers();
    this.ws = new WebSocket(this.wsURL);
    this.ws.onmessage = (message) => this.wsMessage(message);
    this.ws.onerror = (event) => {
      console.log("wsErrorEvent", event);
      //throw new Error("socket error");
    };
    this.ws.onclose = (event) => {
      console.log("wsCloseEvent", event);
      if (!this.blockQueue && rootStore.gameReady) {
        this.tryReconnect();
      }
      //throw new Error("socket closed");
    };
    this.ws.onopen = () => wsOpen.resolve(true);
    await wsOpen.promise;
    rootStore.initFlags.ws = true;
    //window.ws = this.ws;
  }

  @logPerf async initWsAuth() {
    await this.send(null, null, this.token);
    rootStore.initFlags.wsAuth = true;
  }

  @logPerf async initWsState() {
    //const initState = await this.send({ action: "sync" }, "SyncMessage");
    //this.initState = initState as StateResponse;
    this.initState = await this.sendSyncV2();
    rootStore.initFlags.wsSync = true;
  }

  async init() {
    await Promise.all([this.initAvro(), this.initAuthToken()]);
    await Promise.all([
      this.initContentSchemas(),
      this.initOptions(),
      this.initWs(),
    ]);
    await this.initWsAuth();
    await this.initWsState();
    this.readyResolve!();
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        clearTimeout(this.syncTimeout);
        clearTimeout(this.tapTimeout);
      } else {
        this.updateSyncTimeout();
      }
    });
  }

  protected async wsMessage(message: MessageEvent) {
    const arrayBuffer = await message.data.arrayBuffer();
    const buffer = Buffer.from(arrayBuffer);
    const response = camelKeys(this.schemas.Response.fromBuffer(buffer));
    if (!isProduction) {
      console.log("wsMessage:", response);
    }
    if (response.isOk) {
      this.wsResponseResolve!(response);
    } else {
      throw new Error("ws message with error:" + response.error.message);
    }
  }

  protected async sendReconnectAuth() {
    const requestMessage: RequestMessage = { auth: null, content: null };
    requestMessage.auth = this.token!;
    const buffer = this.schemas["Request"].toBuffer(requestMessage);
    this.wsResponsePromise = new Promise((r) => (this.wsResponseResolve = r));
    this.ws!.send(buffer);
    await this.wsResponsePromise;
  }

  protected async tryReconnect() {
    if (!this.queueInProgress) {
      this.blockQueue = true;
      const timeout = setTimeout(() => {
        throw new Error("Reconnect fail (10s)");
      }, 10_000);
      await this.initAuthToken();
      await this.initWs();
      await this.sendReconnectAuth();
      this.blockQueue = false;
      clearTimeout(timeout);
      this.reconnects++;
      //gameStore.devOverlay = true;
    } else {
      throw new Error("ws reconnect fail: queueInProgress");
    }
  }

  protected async send(
    message: ContentMessage | null,
    schema: string | null,
    auth?: string
  ): Promise<ContentResponse> {
    const requestMessage: RequestMessage = { auth: null, content: null };
    if (auth) {
      requestMessage.auth = auth;
    } else if (schema && message) {
      message.id = this.messageCounter;
      // @t/s-expect-error wtf
      //if (this.schemas[schema].fields.find((f) => f.name === "timestamp")) {
      //  message.timestamp = this.timestamp;
      //}
      this.messageCounter++;
      // @ts-expect-error wtf
      console.log(snakeKeys(message));
      if (!this.schemas[schema]) {
        console.error(`Schema ${schema} not found`);
      }
      const snaked = snakeKeys(message as unknown as Record<string, unknown>);
      requestMessage.content = [...this.schemas[schema].toBuffer(snaked)];
    }
    const buffer = this.schemas["Request"].toBuffer(requestMessage);
    const wsMessage = Promise.withResolvers<ContentResponse>();
    const item = { buffer, schema, message, requestMessage, ...wsMessage };
    this.queue.push(item);
    this.processQueue();
    const result = await item.promise;
    return result;
  }

  protected async processQueue() {
    if (this.queueInProgress) return;
    this.queueInProgress = true;
    while (this.blockQueue) {
      await delay(500);
    }
    while (this.queue.length) {
      const item = this.queue.shift();
      this.wsResponsePromise = new Promise((r) => (this.wsResponseResolve = r));
      if (!isProduction) {
        console.log("wsRequest:", item!.requestMessage, item!.message);
      }
      const timeout = setTimeout(() => {
        throw new Error("Backend not answer ws (10s)");
      }, 10_000);
      this.ws!.send(item!.buffer);
      const responceMessage = await this.wsResponsePromise;
      clearTimeout(timeout);
      let decodedContentMessage;
      if (responceMessage.content) {
        decodedContentMessage = this.decodeContent(
          responceMessage.content,
          item!.schema as string
        );
      } else {
        decodedContentMessage = null; // or responceMessage.error
      }
      if (!isProduction) {
        console.log("decoded:", decodedContentMessage);
      }
      item!.resolve(decodedContentMessage);
    }
    this.queueInProgress = false;
  }

  protected decodeContent(content: number[], schema: string): ContentResponse {
    const buffer = Buffer.from(content);
    let result: ContentResponse;
    // @ts-expect-error skip
    const resultSchema = toResultSchema[schema];
    if (!resultSchema) {
      throw new Error("unknown resulting schema");
    }
    try {
      const schema = this.schemas[resultSchema];
      result = camelKeys(schema.fromBuffer(buffer), { deep: true });
    } catch {
      try {
        result = camelKeys(this.schemas.ErrorResponse.fromBuffer(buffer));
        throw new Error("backend error:" + (result as ErrorResponse).message);
      } catch (e) {
        // @ts-expect-error wtf
        if (e.message.startsWith("backend error:")) {
          throw e;
        } else {
          console.error(e);
          throw new Error("Can't decode ws content message");
        }
      }
    }
    return result;
  }
}
