import * as Sentry from "@sentry/react";
import { isProduction } from "./mode";

if (isProduction) {
  Sentry.init({
    dsn: "https://9e85336fa0a3b0e2f66d92abe47af0bb@o4507328241270784.ingest.de.sentry.io/4507963013595216",
    integrations: [],
    sampleRate: 0.02,
  });

  Sentry.setContext("user", { id: Telegram.WebApp.initDataUnsafe.user?.id });
}
